import React from 'react';

import './Form.styles.scss';

export default class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      validForm: false,
      name: '',
      email: '',
      phone: '',
      emailResponse: '',
    };

    this.onNameChange = this.onNameChange.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onMessageChange = this.onMessageChange.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.clearEmailResponse = this.clearEmailResponse.bind(this);
    this.formValidation = this.formValidation.bind(this);
  }

  render() {
    return (
      <form id='contact-form' className='contact-form'>
        <div className='contact-form-title-email'>
          <div className='contact-form__title'>Contact Us</div>
          <div className='contact-form-support-email'>
            Email: info@brookandbaygrooming.com
          </div>
        </div>

        <div className='form-name-email'>
          <div className='form-group name-group'>
            <label>Name</label>
            <input
              name='name'
              type='text'
              className='form-control name'
              id='name'
              onChange={this.onNameChange}
              required
              value={this.state.name}
            />
          </div>
          <div className='form-group email-group'>
            <label htmlFor='exampleInputEmail1'>Email address</label>
            <input
              name='email'
              type='email'
              className='form-control email'
              id='email'
              onChange={this.onEmailChange}
              required
              value={this.state.email}
            />
          </div>
        </div>

        <div className='form-group message-group'>
          <label htmlFor='message'>Message</label>
          <textarea
            className='form-control message'
            id='message'
            name='message'
            onChange={this.onMessageChange}
            required
            value={this.state.message}
          />
          <input
            name='phone'
            type='text'
            className='phone'
            id='phone'
            onChange={(event) => {
              this.setState({ phone: event.target.value });
            }}
            value={this.state.phone}
          />
          <div className='f-button-container'>
            <div className='email-response'>{this.state.emailResponse}</div>
            <button
              type='submit'
              className='btn btn-primary'
              onClick={this.handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    );
  }

  onNameChange(event) {
    this.setState({ name: event.target.value });
  }

  onEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  onMessageChange(event) {
    this.setState({ message: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.formValidation() === true) {
      const templateId = 'template_vAD24mME';
      this.sendFeedback(templateId, {
        message_html: this.state.message,
        from_name: this.state.name,
        reply_to: this.state.email,
      });
    }
  }

  clearForm() {
    this.setState({
      name: '',
      email: '',
      message: '',
    });
  }

  clearEmailResponse() {
    setTimeout(() => {
      this.setState({ emailResponse: '' });
    }, 10000);
  }

  sendFeedback(templateId, variables) {
    window.emailjs.send('gmail', templateId, variables).then((response) => {
      if (response.status === 200) {
        this.setState(
          {
            emailResponse: 'Message sent successfully!',
          },
          this.clearEmailResponse
        );
        this.clearForm();
      } else {
        alert('An error occured, message not sent!');
      }
    });
  }

  formValidation() {
    if (
      this.state.name !== '' &&
      this.state.message !== '' &&
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.state.email) &&
      this.state.phone === ''
    ) {
      return true;
    } else {
      this.setState({ emailResponse: 'One or more fields are incorrect!' });
      return false;
    }
  }
}
