import React from 'react';
import Maps from './components/Maps.component.jsx';
import './App.scss';
import { Link, Element, animateScroll as scroll } from 'react-scroll';
import EmailForm from './components/Form.component';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import bbtrio1 from './assets/bb_trio.jpg';
import bbtrio2 from './assets/bb_trio2.jpg';
import bbtrio3 from './assets/bb_trio3.jpg';

import grooming1 from './assets/grooming_1.jpg';
import grooming2 from './assets/grooming_2.jpg';
import grooming3 from './assets/grooming_3.jpg';

import before1 from './assets/before_1.jpg';
import before2 from './assets/before_2.jpg';
import after1 from './assets/after_1.jpg';
import after2 from './assets/after_2.jpg';

class App extends React.Component {
  static defaultProps = {
    center: {
      lat: 37.601,
      lng: -87.117,
    },
    zoom: 10,
  };
  constructor(props) {
    super(props);
    this.scrollToTop = this.scrollToTop.bind(this);
  }

  scrollToTop() {
    scroll.scrollToTop();
  }

  render() {
    return (
      <div className='App'>
        <div className='spacer-w'></div>

        <div className='main-container'>
          <div className='header'>
            <Navbar bg='#fff' expand='lg' className='navbar'>
              <Navbar.Brand className='logo' onClick={() => this.scrollToTop()}>
                B&B
              </Navbar.Brand>
              <Navbar.Toggle />
              <Navbar.Collapse id='basic-navbar-nav' className='nav-end'>
                <Nav className='mr-auto'>
                  <Nav.Link
                    as={Link}
                    activeClass='active'
                    className='link-grooming link'
                    to='grooming'
                    spy={true}
                    smooth={true}
                    duration={100}
                  >
                    Grooming
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    activeClass='active'
                    className='link-boarding link'
                    to='boarding'
                    spy={true}
                    smooth={true}
                    duration={100}
                  >
                    Boarding
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    activeClass='active'
                    className='link-contact link'
                    to='contact-form'
                    spy={true}
                    smooth={true}
                    duration={100}
                  >
                    Contact Us
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
          <div className='top'>
            <div className='image-bar-container'>
              <div className='brand-container'>
                <div className='brand'>Brook & Bay Grooming </div>
              </div>
              <div className='image-bar'>
                <div className='button-container'>
                  <Link
                    activeClass='active'
                    className='link-contact'
                    to='contact-form'
                    spy={true}
                    smooth={true}
                    duration={100}
                  >
                    <button type='submit' className='contact-us-button'>
                      Contact Us
                    </button>
                  </Link>
                  <Link
                    activeClass='active'
                    className='link-grooming'
                    to='grooming'
                    spy={true}
                    smooth={true}
                    duration={100}
                  >
                    <button type='submit' className='services-button'>
                      Services
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className='bio'>
            <div className='bio__title'>Welcome to Brook and Bay Grooming!</div>
            <div className='bio__description'>
              I'm Brooklyn, I graduated from Nash Grooming Academy in the spring
              of 2020. I'm certified in canine CPR & safety. I'm proud to say I
              was a top student and was invited to attend a grooming competition
              in Australia. However, I was excited to return home and start
              grooming my furry friends in Owensboro. Give me a call and book
              your appointment today!
            </div>
            <Element name='grooming'></Element>
          </div>

          <div className='grooming'>
            <div className='g-outer-image-container'>
              <div className='grooming-image-container'>
                <div>
                  <img
                    className='grooming-image'
                    src={grooming1}
                    alt='grooming1'
                  />
                </div>
                <div>
                  <img
                    className='grooming-image'
                    src={grooming2}
                    alt='grooming2'
                  />
                </div>
                <div>
                  <img
                    className='grooming-image'
                    src={grooming3}
                    alt='grooming3'
                  />
                </div>
              </div>
            </div>
            <div className='grooming__head'>
              <div className='grooming__head__title'>Grooming</div>

              <div className='grooming__head__about'>
                Please note that pricing varies by size, temperament, and coat
                condition. Shown prices are estimates.
              </div>
            </div>
            <div className='grooming__info'>
              <div className='grooming-pricing-container'>
                <div className='the-works'>
                  <div className='the-works__title'>The Works</div>
                  <div className='the-works__description'>
                    Your pet will receive a bath, sanitary trim, nails done,
                    clean paw pads, ears cleaned, haircut and style with hand
                    scissor work specified by owner.
                  </div>
                  <div className='the-works__pricing'>
                    <div className='the-works__pricing__item'>
                      <div className='w-pricing-item-title'>Small:</div>
                      <div className='w-pricing-item-price'>$40 - 50</div>
                    </div>
                    <div className='the-works__pricing__item'>
                      <div className='w-pricing-item-title'>Medium:</div>
                      <div className='w-pricing-item-price'>$50 - 60</div>
                    </div>
                    <div className='the-works__pricing__item'>
                      <div className='w-pricing-item-title'>Large:</div>
                      <div className='w-pricing-item-price'>$60 - 75</div>
                    </div>
                  </div>
                </div>

                <div className='regular-grooming'>
                  <div className='regular-grooming__title'>The Basics</div>

                  <div className='regular-grooming__detail'>
                    Includes a relaxing message bath, blowout, nail trimming,
                    ear, cleaning, and sanitary trim
                  </div>

                  <div className='regular-grooming__pricing'>
                    <div className='regular-grooming__pricing__item'>
                      <div className='r-pricing-item-title'>Small:</div>
                      <div className='r-pricing-item-price'>$25+</div>
                    </div>
                    <div className='regular-grooming__pricing__item'>
                      <div className='r-pricing-item-title'>Medium:</div>
                      <div className='r-pricing-item-price'>$30+</div>
                    </div>
                    <div className='regular-grooming__pricing__item'>
                      <div className='r-pricing-item-title'>Large:</div>
                      <div className='r-pricing-item-price'>$40+</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='grooming-additional-container'>
                <div className='additional-grooming'>
                  <div className='additional-grooming__title'>
                    Something Special to Add On
                  </div>
                  <div className='additional-grooming__item'>
                    Teeth Brushing
                  </div>
                  <div className='additional-grooming__item'>
                    Dremeling Nails
                  </div>
                  <div className='additional-grooming__item'>Mud Bath</div>
                  <div className='additional-grooming__item'>
                    Deshedding Treatment
                  </div>
                  <div className='additional-grooming__item'>Anal Glands</div>
                  <div className='additional-grooming__item'>Paw pad cream</div>
                  <div className='additional-grooming__item'>
                    Whitening Shampoo
                  </div>
                </div>
                <div className='g-size-guide'>
                  <div className='g-size-guide__title'>Size Guide</div>
                  <div className='g-size-guide-container'>
                    <div className='g-size-guide-item'>
                      <div className='size-title'>Small:</div>
                      <div className='size-detail'>Up to 9 pounds</div>
                    </div>
                    <div className='g-size-guide-item'>
                      <div className='size-title'>Medium:</div>
                      <div className='size-detail'>Up to 40 pounds</div>
                    </div>
                    <div className='g-size-guide-item'>
                      <div className='size-title'>Large:</div>
                      <div className='size-detail'>Up to 100 pounds</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='g-outer-image-container beforeAfter'>
              <div className='grooming-image-container'>
                <div>
                  <img
                    className='grooming-image'
                    src={before1}
                    alt='grooming1'
                  />
                </div>
                <div>
                  <img
                    className='grooming-image'
                    src={after1}
                    alt='grooming2'
                  />
                </div>
                <div>
                  <img
                    className='grooming-image'
                    src={before2}
                    alt='grooming3'
                  />
                </div>
                <div>
                  <img
                    className='grooming-image'
                    src={after2}
                    alt='grooming3'
                  />
                </div>
              </div>
              <Element name='boarding'></Element>
            </div>
          </div>

          <div className='boarding-about'>
            <div className='boarding-head'>
              <div className='boarding-title'>Boarding</div>
              <div className='about-text'>
                A few things you will want to know. You can leave your pet with
                ease, knowing that they will have so much fun while they are
                boarding with us. They will be able to see Dad & Mom as well as
                play with siblings or cousins, depending on who is boarding with
                us at the time. They will leave happy & tired.
              </div>
            </div>

            <div className='boarding-information'>
              <div className='boarding-require-columns'>
                <div className='boarding-list-container'>
                  <div className='boarding-list-container__title'>
                    We will provide the following:
                  </div>
                  <div className='boarding-list-container__item'>
                    Individual condo for your pet with a coolaroo bed
                  </div>
                  <div className='boarding-list-container__item'>
                    Food and water bowl
                  </div>
                  <div className='boarding-list-container__item'>
                    Monitored playtime
                  </div>
                  <div className='boarding-list-container__item'>Shade</div>
                  <div className='boarding-list-container__item'>
                    Fresh water
                  </div>
                  <div className='boarding-list-container__item'>
                    Room to run
                  </div>

                  <div className='boarding-list-container__item'>
                    Doodle daycare is included!
                  </div>
                </div>

                <div className='boarding-requirement-container'>
                  <div className='boarding-requirement-container__title'>
                    We ask that you bring:
                  </div>
                  <div className='boarding-requirement-container__item'>
                    Food broken down in daily increments (in containers) with
                    their name on it
                  </div>
                  <div className='boarding-requirement-container__item'>
                    Copy of vet records (please have them vaccinated with Kennel
                    Cough)
                  </div>
                  <div className='boarding-requirement-container__item'>
                    Any special instruction
                  </div>
                </div>
              </div>

              <div className='boarding-special-note'>
                Please note: Favorite toys, blankets or treats are welcome as to
                make your pet as comfortable as possible while they are with us.
              </div>

              <div className='boarding-time-pricing'>
                <div className='boarding-times'>
                  <div className='boarding-times__title'>
                    Drop Off and Pick Up Times:
                  </div>
                  <div className='boarding-times__item'>
                    <span className='boarding-times-subtitle'>
                      Drop off (Mon - Sat):
                    </span>{' '}
                    8:30 - 9:00 AM
                  </div>
                  <div className='boarding-times__item'>
                    <span className='boarding-times-subtitle'>
                      Pick up (Mon - Sat):
                    </span>{' '}
                    5:30 - 6:00 PM
                  </div>
                </div>
                <div className='boarding-pricing'>
                  <div className='boarding-pricing__title'>Pricing:</div>
                  <div className='boarding-pricing__short'>
                    <div className='boarding-pricing__short__title'>
                      8:30 am - 12:30 pm:
                    </div>
                    <div className='boarding-pricing__short__price'>
                      $15 per day
                    </div>
                  </div>
                  <div className='boarding-pricing__long'>
                    <div className='boarding-pricing__long__title'>
                      8:30 am - 5:00 pm:
                    </div>
                    <div className='boarding-pricing__long__price'>
                      $25 per day
                    </div>
                  </div>
                  <div className='boarding-pricing__night'>
                    <div className='boarding-pricing__night__title'>
                      Day and Night:
                    </div>
                    <div className='boarding-pricing__night__price'>
                      $35 per day/night
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='outer-image-container'>
              <div className='boarding-image-container'>
                <div>
                  <img className='boarding-image' src={bbtrio1} alt='bbtrio1' />
                </div>
                <div>
                  <img className='boarding-image' src={bbtrio2} alt='bbtrio2' />
                </div>
                <div>
                  <img className='boarding-image' src={bbtrio3} alt='bbtrio3' />
                </div>
                <Element name='contact-form'></Element>
              </div>
            </div>
          </div>

          <div className='form-map'>
            <EmailForm />
            <div className='map-container'>
              <Maps />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
