import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import './Maps.component.scss';

const mapStyles = {
  width: '100%',
  height: '100%',
  position: 'relative',
};

const containerStyle = {
  position: 'relative',
  width: '100%',
  height: '100%',
};

export class MapContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cords: [{ lat: 37.600757, lng: -87.117712 }],
    };
  }

  showMarkers = () => {
    return this.state.cords.map((store, index) => {
      return (
        <Marker
          key={index}
          id={index}
          position={{
            lat: store.lat,
            lng: store.lng,
          }}
          onClick={() => console.log('Clicked')}
        />
      );
    });
  };

  render() {
    return (
      <Map
        google={this.props.google}
        containerStyle={containerStyle}
        zoom={10}
        style={mapStyles}
        initialCenter={{
          lat: 37.600757,
          lng: -87.117712,
        }}
        streetViewControl={false}
        scrollwheel={true}
        mapTypeControl={false}
      >
        {this.showMarkers()}
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyD3IkJx9gnI5PpRnlM2srzfjLl8ULfbPXo',
})(MapContainer);
